// src/config/appConfig.ts

export const APP_CONFIG = {
    // Флаг для включения тестового режима
    TEST_MODE: true, //false/true
    
    // Тестовый пользователь
    TEST_USER: {
      id: 123456,
      first_name: "Test User",
      username: "@testuser",
      photo_url: null
    }
  };
  
  // Функция для получения ID пользователя
  export const getUserId = (): number => {
    if (APP_CONFIG.TEST_MODE) {
      return APP_CONFIG.TEST_USER.id;
    }
    
    const webApp = window.Telegram?.WebApp;
    return webApp?.initDataUnsafe?.user?.id || 0;
  };
  
  // Функция для получения данных пользователя
  export const getUserData = () => {
    if (APP_CONFIG.TEST_MODE) {
      return APP_CONFIG.TEST_USER;
    }
    
    return window.Telegram?.WebApp?.initDataUnsafe?.user || null;
  };