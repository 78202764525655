import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { coinFlipApi } from '../api/coinFlipApi';
import RecentGamesTable from './RecentGamesTable';

interface GameResult {
  side: 'BTC' | 'ETH';
  timestamp: Date;
}

interface RecentGame {
  user: string;
  bet: number;
  coef: number;
  win: number | null;
  timestamp: string;
}

const BitcoinFlipGame = () => {
  const [userId] = useState<number>(123456);
  const [balance, setBalance] = useState<number>(0); // Инициализируем с 0
  const [betAmount, setBetAmount] = useState<number>(0.01);
  const [selectedSide, setSelectedSide] = useState<'BTC' | 'ETH' | null>(null);
  const [isFlipping, setIsFlipping] = useState<boolean>(false);
  const [flipResult, setFlipResult] = useState<'BTC' | 'ETH' | null>(null);
  const [gameResults, setGameResults] = useState<GameResult[]>([]);
  const [currentStreak, setCurrentStreak] = useState<number>(0);
  const [maxStreak, setMaxStreak] = useState<number>(0);
  const [multiplier, setMultiplier] = useState<number>(1.95);
  const [recentGames, setRecentGames] = useState<RecentGame[]>([]);
  const [newResult, setNewResult] = useState<GameResult | null>(null);

  // Добавьте функцию для загрузки баланса
const fetchBalance = async () => {
  try {
    const response = await coinFlipApi.getUserBalance(userId);
    setBalance(response.balance);
  } catch (error) {
    console.error('Error fetching balance:', error);
  }
};

useEffect(() => {
  const loadInitialData = async () => {
    try {
      // Загружаем баланс
      await fetchBalance();
      
      // Загружаем остальные данные
      const stats = await coinFlipApi.getUserStats(userId);
      setCurrentStreak(stats.currentStreak);
      setMaxStreak(stats.maxStreak);
      
      const results = await coinFlipApi.getLastResults(userId);
      setGameResults(results.map(r => ({
        side: r.result,
        timestamp: new Date(r.createdAt)
      })));
      
      const recentGames = await coinFlipApi.getRecentGames(10);
      setRecentGames(recentGames);
    } catch (error) {
      console.error('Error loading initial data:', error);
    }
  };


  loadInitialData();
  // Обновляем баланс каждые 5 секунд
  const balanceInterval = setInterval(fetchBalance, 5000);
  return () => clearInterval(balanceInterval);
}, [userId]);

// Обновите функцию handleBetAmountChange
const handleBetAmountChange = (type: 'min' | 'x2' | 'max') => {
  switch (type) {
    case 'min':
      setBetAmount(0.01);
      break;
    case 'x2':
      setBetAmount(currentBet => {
        const doubled = currentBet * 2;
        return doubled > balance ? balance : doubled;
      });
      break;
    case 'max':
      if (balance > 0) {
        setBetAmount(balance);
      } else {
        // Можно добавить уведомление что баланс пуст
        console.log('Balance is empty');
      }
      break;
  }
};

  const handleSideSelection = async (side: 'BTC' | 'ETH') => {
    if (isFlipping || betAmount > balance) return;
    
    setSelectedSide(side);
    setIsFlipping(true);
    
    try {
      const result = await coinFlipApi.play(userId, betAmount, side);
      setFlipResult(result.result);
      
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setNewResult({ 
        side: result.result, 
        timestamp: new Date(result.createdAt) 
      });
      
      setTimeout(() => {
        setNewResult(null);
        setGameResults(prev => {
          const newResults = [...prev];
          newResults.unshift({ 
            side: result.result, 
            timestamp: new Date(result.createdAt) 
          });
          return newResults.slice(0, 5);
        });
      }, 300);
      
      setBalance(prev => prev + (result.winAmount - betAmount));
      setCurrentStreak(result.streak);
      setMaxStreak(prev => Math.max(prev, result.streak));
      setMultiplier(1.95 + (result.streak * 0.01));
      
    } catch (error) {
      console.error('Error during flip:', error);
    } finally {
      setIsFlipping(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">      
      <div className="max-w-md mx-auto p-4">
        <h1 className="text-2xl font-bold text-center mb-8">Bitcoin Flip</h1>

        {/* История последних попыток */}
        <div className="flex justify-center gap-2 mb-8">
          <motion.div 
            key="new-slot"
            className="w-8 h-8 rounded-full bg-zinc-800 flex items-center justify-center overflow-hidden"
            layout
          >
            {newResult ? (
              <motion.img 
                src={`/images/coinflip/${newResult.side.toLowerCase()}_coin.png`}
                alt={newResult.side}
                className="w-full h-full object-contain"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.2 }}
              />
            ) : (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                ?
              </motion.span>
            )}
          </motion.div>
          
          {[...Array(5)].map((_, index) => (
            <motion.div 
              key={`slot-${index}`}
              className="w-8 h-8 rounded-full bg-zinc-800 flex items-center justify-center overflow-hidden"
              layout
              transition={{ 
                layout: { duration: 0.3, type: "spring", stiffness: 300, damping: 30 }
              }}
            >
              {gameResults[index] ? (
                <motion.img 
                  src={`/images/coinflip/${gameResults[index].side.toLowerCase()}_coin.png`}
                  alt={gameResults[index].side}
                  className="w-full h-full object-contain"
                  layoutId={`coin-${index}`}
                />
              ) : (
                <motion.span>?</motion.span>
              )}
            </motion.div>
          ))}
        </div>

        {/* Основная игровая область */}
        <div className="text-center mb-8">
          <div className="mb-4 text-2xl font-medium">{currentStreak} раунд</div>
          
          {/* Монета с 3D анимацией */}
          <div className="perspective-1000 relative w-48 h-48 mx-auto mb-8">
            <motion.div 
              className="w-full h-full absolute preserve-3d"
              animate={{ 
                rotateY: isFlipping 
                  ? 720 + (flipResult === 'ETH' ? 180 : 0)
                  : flipResult === 'ETH' ? 180 : 0,
              }}
              transition={{ 
                duration: 2,
                ease: "easeInOut"
              }}
            >
              <div className="w-full h-full rounded-full absolute backface-hidden">
                <img 
                  src="/images/coinflip/btc_coin.png" 
                  alt="BTC" 
                  className="w-full h-full object-contain"
                />
              </div>
              <div 
                className="w-full h-full rounded-full absolute backface-hidden"
                style={{ transform: 'rotateY(180deg)' }}
              >
                <img 
                  src="/images/coinflip/eth_coin.png" 
                  alt="ETH" 
                  className="w-full h-full object-contain"
                />
              </div>
            </motion.div>
          </div>

          <div className="flex justify-between px-4 text-xl">
            <div>x{multiplier.toFixed(2)} кэф</div>
            <div>{maxStreak} рекорд</div>
          </div>
        </div>

        {/* Кнопки выбора стороны */}
        <div className="flex gap-4 mb-6">
          <button 
            onClick={() => !isFlipping && handleSideSelection('BTC')}
            className={`flex-1 py-3 px-4 rounded-xl flex items-center justify-center gap-2
              bg-zinc-800 border-2 transition-all duration-200
              ${selectedSide === 'BTC' ? 'border-blue-500' : 'border-transparent'}
              ${isFlipping ? 'opacity-50 cursor-not-allowed' : 'hover:border-blue-500/50'}`}
          >
            <img 
              src="/images/coinflip/btc_coin.png" 
              alt="BTC" 
              className="w-6 h-6 object-contain"
            />
            BTC
          </button>
          <button 
            onClick={() => !isFlipping && handleSideSelection('ETH')}
            className={`flex-1 py-3 px-4 rounded-xl flex items-center justify-center gap-2
              bg-zinc-800 border-2 transition-all duration-200
              ${selectedSide === 'ETH' ? 'border-blue-500' : 'border-transparent'}
              ${isFlipping ? 'opacity-50 cursor-not-allowed' : 'hover:border-blue-500/50'}`}
          >
            <img 
              src="/images/coinflip/eth_coin.png" 
              alt="ETH" 
              className="w-6 h-6 object-contain"
            />
            ETH
          </button>
        </div>

        {/* Контроль ставки */}
        <div className="bg-zinc-900 rounded-3xl p-4 mb-6">
          <div className="flex items-center justify-between">
            <div className="text-xl font-medium px-4">{betAmount}$</div>
            <div className="flex gap-2">
              <button 
                onClick={() => handleBetAmountChange('min')}
                className="bg-zinc-800 hover:bg-zinc-700 px-6 py-2 rounded-xl transition-colors"
              >
                min
              </button>
              <button 
                onClick={() => handleBetAmountChange('x2')}
                className="bg-zinc-800 hover:bg-zinc-700 px-6 py-2 rounded-xl transition-colors"
              >
                x2
              </button>
              <button 
                onClick={() => handleBetAmountChange('max')}
                className="bg-zinc-800 hover:bg-zinc-700 px-6 py-2 rounded-xl transition-colors"
              >
                max
              </button>
            </div>
          </div>
        </div>

        {/* Таблица последних игр */}
        {/* <div className="bg-zinc-900 rounded-3xl p-4 overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="text-gray-400">
                <th width="25%" className="text-left py-2 px-3">Игрок</th>
                <th width="20%" className="text-left py-2 px-3">Ставка</th>
                <th width="15%" className="text-left py-2 px-3">Кэф</th>
                <th width="25%" className="text-left py-2 px-3">Выигрыш</th>
                <th width="15%" className="text-left py-2 px-3">Время</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-zinc-700">
              {recentGames.map((game, index) => (
                <tr key={index} className="text-sm hover:bg-zinc-800 transition-colors">
                  <td className="py-2 px-3">
                    {game.user}
                  </td>
                  <td className="py-2 px-3">
                    {game.bet.toFixed(2)}$
                  </td>
                  <td className="py-2 px-3">
                    x{game.coef.toFixed(2)}
                  </td>
                  <td className="py-2 px-3">
                    {game.win === null ? (
                      <span className="text-red-500">-{game.bet.toFixed(2)}$</span>
                    ) : (
                      <span className="text-green-500">+{game.win.toFixed(2)}$</span>
                    )}
                  </td>
                  <td className="py-2 px-3 text-gray-400">
                    {game.timestamp}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

<div className="mt-6">
  <RecentGamesTable />
</div>
      </div>

      <style jsx global>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .preserve-3d {
          transform-style: preserve-3d;
        }
        .backface-hidden {
          backface-visibility: hidden;
        }
      `}</style>
    </div>
  );
};

export default BitcoinFlipGame;