import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BottomNav: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    try {
      const webApp = window.Telegram?.WebApp;
      if (webApp?.HapticFeedback) {
        webApp.HapticFeedback.impactOccurred('medium');
      }
      navigate(path);
    } catch (error) {
      console.error('Ошибка при вызове HapticFeedback:', error);
      navigate(path);
    }
  };

  const navItems = [
    { icon: 'Home', label: 'Home', link: '/' },
    { icon: 'Gift', label: 'Bonuses', link: '/bonuses' },
    { icon: 'Star', label: 'Favorites', link: '/favorites' },
    { icon: 'Settings', label: 'Settings', link: '/coin-flip' }
  ];

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-zinc-800 border border-zinc-800 bg-opacity-50 backdrop-blur z-50 p-4 rounded-xl shadow-lg w-[90%] max-w-md">
      <div className="grid grid-cols-4 gap-4">
        {navItems.map((item, index) => (
          <div 
            key={index} 
            className={`flex flex-col items-center cursor-pointer transition duration-300 ease-in-out ${location.pathname === item.link ? 'text-blue-500' : 'text-gray-400'}`} 
            onClick={() => handleNavigation(item.link)}
          >
            {React.createElement(require('lucide-react')[item.icon], { 
              className: `transition duration-300 ${location.pathname === item.link ? 'text-blue-500' : 'text-gray-400'}` 
            })}
            <span className={`text-xs mt-1 transition duration-300 ${location.pathname === item.link ? 'text-blue-500' : 'text-gray-400'}`}>
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomNav;