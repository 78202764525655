import React from 'react';
import BitcoinFlipGame from '../components/BitcoinFlipGame';

const CoinFlipPage: React.FC = () => {
  React.useEffect(() => {
    const initTelegram = () => {
      try {
        const webApp = window.Telegram?.WebApp;
        if (webApp) {
          webApp.ready();
          if (typeof webApp.disableSwipeToDismiss === 'function') {
            webApp.disableSwipeToDismiss();
          }
        }
      } catch (error) {
        console.error('Ошибка при инициализации Telegram WebApp:', error);
      }
    };

    setTimeout(initTelegram, 100);
  }, []);

  return (
    <div className="min-h-screen pb-16">
      <BitcoinFlipGame />
    </div>
  );
};

export default CoinFlipPage;