import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { coinFlipApi, RecentGame } from '../api/coinFlipApi';

const RecentGamesTable = () => {
  const [games, setGames] = useState<RecentGame[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGames = async () => {
    try {
      const results = await coinFlipApi.getRecentGames(10);
      
      setGames(prevGames => {
        // Если данные те же самые - не обновляем
        if (JSON.stringify(prevGames) === JSON.stringify(results)) {
          return prevGames;
        }
        return results;
      });
      
      setError(null);
    } catch (error) {
      console.error('Error fetching games:', error);
      setError(error.response?.data?.message || error.message || 'Failed to load recent games');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
    const interval = setInterval(fetchGames, 2000); // Обновление каждые 2 секунды
    return () => clearInterval(interval);
  }, []);

  if (loading && games.length === 0) {
    return (
      <div className="bg-zinc-900 rounded-3xl p-4">
        <h2 className="text-xl font-semibold mb-4 text-white">Recent Games</h2>
        <div className="flex justify-center items-center py-8">
          <motion.div
            animate={{
              rotate: 360
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "linear"
            }}
            className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-zinc-900 rounded-3xl p-4 overflow-hidden">
      {/* <h2 className="text-xl font-semibold mb-4 text-white">Recent Games</h2> */}
      
      {error ? (
        <div className="text-red-400 text-center py-4 flex items-center justify-center gap-3">
          <span>{error}</span>
          <button 
            onClick={fetchGames}
            className="px-3 py-1 bg-zinc-800 rounded-lg hover:bg-zinc-700 transition-colors"
          >
            Retry
          </button>
        </div>
      ) : games.length === 0 ? (
        <div className="text-gray-400 text-center py-4">
          No games found
        </div>
      ) : (
        <div className="overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="text-gray-400 text-sm">
                <th className="text-left py-2">Player</th>
                <th className="text-right py-2">Bet</th>
                <th className="text-right py-2">Multiplier</th>
                <th className="text-right py-2">Result</th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence mode="popLayout">
                {games.map((game, index) => (
                  <motion.tr
                    key={`${game.user}-${game.bet}-${game.win}-${index}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.2 }}
                    className="text-sm border-t border-zinc-800"
                  >
                    <td className="py-3 text-gray-300">
                      {typeof game.user === 'string' 
                        ? game.user.length > 10 
                          ? `${game.user.slice(0, 10)}...` 
                          : game.user
                        : 'Unknown'}
                    </td>
                    <td className="py-3 text-right text-gray-300">
                      {typeof game.bet === 'number' 
                        ? `${game.bet.toFixed(2)}$`
                        : `${game.bet}$`}
                    </td>
                    <td className="py-3 text-right text-blue-400">
                      {typeof game.coef === 'number'
                        ? `x${game.coef.toFixed(2)}`
                        : `x${game.coef}`}
                    </td>
                    <td className={`py-3 text-right ${
                      game.win !== null ? 'text-green-500' : 'text-red-500'
                    }`}>
                      {game.win !== null
                        ? `+${typeof game.win === 'number' ? game.win.toFixed(2) : game.win}$`
                        : `-${typeof game.bet === 'number' ? game.bet.toFixed(2) : game.bet}$`}
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RecentGamesTable;