// src/components/RankDisplay.tsx

import React from 'react';
import { motion } from 'framer-motion';
import { getUserId } from '../config/appConfig';
import { useRankInfo } from '../hooks/useRankInfo';

export const RankDisplay: React.FC = () => {
  const userId = getUserId();
  const { rankInfo, loading, error } = useRankInfo(userId);

  if (loading) {
    return (
      <div className="bg-zinc-900 rounded-lg p-4 animate-pulse">
        <div className="h-4 bg-zinc-800 rounded w-3/4 mb-4"></div>
        <div className="h-4 bg-zinc-800 rounded w-1/2"></div>
      </div>
    );
  }

  if (error || !rankInfo) {
    return (
      <div className="bg-zinc-900 rounded-lg p-4 text-red-500">
        Failed to load rank information
      </div>
    );
  }

  const { currentRank, nextRank, score, progress, pointsToNextRank } = rankInfo;

  return (
    <div className="bg-zinc-900 rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <span className="text-2xl">{currentRank.icon}</span>
          <div>
            <h3 
              className="text-lg font-semibold" 
              style={{ color: currentRank.color }}
            >
              {currentRank.title}
            </h3>
            <p className="text-sm text-zinc-400">
              {score.toLocaleString()} points
            </p>
          </div>
        </div>
        
        {nextRank && (
          <div className="text-right">
            <p className="text-sm text-zinc-400">
              Next: {nextRank.title}
            </p>
            <p className="text-xs text-zinc-500">
              {pointsToNextRank?.toLocaleString()} points needed
            </p>
          </div>
        )}
      </div>

      {nextRank && (
        <div className="relative h-2 bg-zinc-800 rounded-full overflow-hidden">
          <motion.div
            className="absolute h-full rounded-full"
            style={{ backgroundColor: currentRank.color }}
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>
      )}
    </div>
  );
};