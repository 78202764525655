import React from 'react';  

interface TableCardProps {
    offers: {
        logoUrl: string;
        title: string;
        bonusTypes: string[];
        link: string;
    }[];
}
const TableCard: React.FC<TableCardProps> = ({ offers }) => {
    // Разбиваем офферы на группы по 5
    const offerGroups = offers.reduce((acc, curr, i) => {
      const groupIndex = Math.floor(i / 5);
      if (!acc[groupIndex]) acc[groupIndex] = [];
      acc[groupIndex].push(curr);
      return acc;
    }, [] as typeof offers[]);
  
    // Функция для определения цвета бонуса
    const getBonusTypeColor = (bonusType: string) => {
      if (bonusType.includes('FS')) return 'bg-green-400 bg-opacity-30 text-green-400';
      if (bonusType.includes('₽')) return 'bg-blue-400 bg-opacity-30 text-blue-400';
      if (bonusType.includes('%')) return 'bg-purple-400 bg-opacity-30 text-purple-400';
      return 'bg-yellow-400 bg-opacity-30 text-yellow-400';
    };
  
    return (
      <div className="carousel-container">
        <div className="flex space-x-4 pb-4">
          {offerGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="bg-zinc-900 rounded-3xl min-w-[320px] p-4">
              <div className="flex flex-col space-y-4">
                {group.map((offer, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <img 
                        src={offer.logoUrl} 
                        alt="Logo" 
                        className="w-12 h-12 rounded-xl object-cover"
                      />
                      <div className="flex flex-col">
                        <span className="text-white font-medium">{offer.title}</span>
                        <div className="flex flex-wrap gap-1 mt-1">
                          {offer.bonusTypes.map((bonusType, bIndex) => (
                            <span 
                              key={bIndex}
                              className={`${getBonusTypeColor(bonusType)}  text-xs px-2 py-0.5 rounded-full`}
                            >
                              {bonusType}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <button 
                      className="bg-zinc-800 bg-opacity-50 text-white px-4 py-1.5 rounded-lg transition-colors text-sm"
                      onClick={() => window.open(offer.link, '_blank')}
                    >
                      Играть
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
export default TableCard;