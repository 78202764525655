import React from 'react';

interface FeatureCardProps {
    title: string;
    imageUrl: string;
    className?: string;
  }

  const FeatureCard: React.FC<FeatureCardProps> = ({ title, imageUrl, className = '' }) => (
    <div className={`bg-zinc-900 rounded-3xl relative overflow-hidden ${className}`}>
      <img src={imageUrl} alt={title} className="w-full h-full object-cover" />
      <h2 className="text-xl absolute bottom-4 left-4 text-white">{title}</h2>
    </div>
  );
  


  export default FeatureCard;