import axios from 'axios';

const API_BASE_URL = '/api';

export const coinFlipApi = {
  play: async (userId: number, bet: number, choice: 'BTC' | 'ETH') => {
    try {
      const response = await axios.post(`${API_BASE_URL}/coin-flip/play`, {
        userId,
        bet,
        choice
      });
      return response.data;
    } catch (error) {
      console.error('Error making bet:', error);
      throw error;
    }
  },

  getLastResults: async (userId: number) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/coin-flip/${userId}/results`);
      return response.data;
    } catch (error) {
      console.error('Error fetching results:', error);
      throw error;
    }
  },

  getUserStats: async (userId: number) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/coin-flip/${userId}/stats`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user stats:', error);
      throw error;
    }
  },

  getRecentGames: async (limit: number = 10) => {
    try {
      console.log('Fetching recent games, API URL:', `${API_BASE_URL}/coin-flip/recent?limit=${limit}`);
      const response = await axios.get(`${API_BASE_URL}/coin-flip/recent?limit=${limit}`);
      console.log('Recent games API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      throw error;
    }
  },


  getUserBalance: async (userId: number) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userId}/balance`);
      return response.data;
    } catch (error) {
      console.error('Ошибка при получении баланса:', error);
      throw error;
    }
  }
};

// Интерфейсы для типизации ответов API
export interface GameResult {
  id: string;
  userId: string;
  bet: number;
  choice: 'BTC' | 'ETH';
  result: 'BTC' | 'ETH';
  win: boolean;
  winAmount: number;
  coefficient: number;
  streak: number;
  createdAt: string;
}

export interface UserStats {
  currentStreak: number;
  maxStreak: number;
  totalGames: number;
  winRate: number;
}

export interface RecentGame {
  user: string;
  bet: string | number;
  coef: string | number;
  win: string | number | null;
}

export interface UserBalance {
  balance: number;
}