// src/hooks/useRankInfo.ts

import { useState, useEffect } from 'react';
import { rankApi } from '../api/rankApi';

export const useRankInfo = (userId: number) => {
  const [rankInfo, setRankInfo] = useState<RankInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRankInfo = async () => {
    try {
      setLoading(true);
      const data = await rankApi.getUserRankInfo(userId);
      setRankInfo(data);
      setError(null);
    } catch (error) {
      setError('Failed to fetch rank info');
      console.error('Error in useRankInfo:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRankInfo();
    
    // Обновляем информацию каждые 30 секунд
    const interval = setInterval(fetchRankInfo, 30000);
    
    return () => clearInterval(interval);
  }, [userId]);

  return { rankInfo, loading, error, refetch: fetchRankInfo };
};