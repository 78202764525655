// AnimatedPage.tsx
import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedPageProps {
  children: React.ReactNode;
  direction: 'left' | 'right';
}

const AnimatedPage: React.FC<AnimatedPageProps> = ({ children, direction }) => {
  const offset = 200; // Смещение для эффекта замещения

  const variants = {
    initial: {
      x: direction === 'right' ? offset : -offset,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: direction === 'right' ? offset : -offset,
      opacity: 0,
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      style={{ position: 'absolute', width: '100%' }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
