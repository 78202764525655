// App.tsx
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import HomePage from './pages/HomePage';
import BonusesPage from './pages/BonusesPage';
import FavoritesPage from './pages/FavoritesPage';
import BottomNav from './components/BottomNav';
import AnimatedPage from './components/AnimatedPage';
import Navigation from './components/Navigation';
import CoinFlipPage from './pages/CoinFlipPage';

const AnimatedRoutes = () => {
  const location = useLocation();
  const [direction, setDirection] = useState<'left' | 'right'>('right');
  const [prevPath, setPrevPath] = useState(location.pathname);

  useEffect(() => {
    // Установка направления в зависимости от сравнения текущего и предыдущего пути
    if (prevPath !== location.pathname) {
      setDirection(prevPath < location.pathname ? 'right' : 'left');
      setPrevPath(location.pathname); // Обновление предыдущего пути
    }
  }, [location, prevPath]);

  return (
    <AnimatePresence initial={false} mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={
          <AnimatedPage direction={direction}>
            <HomePage />
          </AnimatedPage>
        } />
        <Route path="/bonuses" element={
          <AnimatedPage direction={direction}>
            <BonusesPage />
          </AnimatedPage>
        } />
         <Route path="/favorites" element={
          <AnimatedPage direction={direction}>
            <FavoritesPage />
          </AnimatedPage>
        } />
        <Route path="/coin-flip" element={
          <AnimatedPage direction={direction}>
            <CoinFlipPage />
          </AnimatedPage>
        } />
        {/* Добавьте другие маршруты здесь */}
      </Routes>
    </AnimatePresence>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen overflow-auto relative">
        <Navigation />
        <div className="absolute w-full">
          <AnimatedRoutes />
        </div>
        <BottomNav />
      </div>
    </Router>
  );
};

export default App;
