import React from 'react';

interface CasinoCardProps {
    logoUrl: string; // URL логотипа
    title: string;   // Подпись
    subtitle: string; // Подзаголовок
    link: string;    // Ссылка для кнопки
  }

  const CasinoCard: React.FC<CasinoCardProps> = ({ logoUrl, title, subtitle, link }) => (
    <div className="bg-zinc-900 rounded-3xl w-40 h-60 flex-shrink-0 flex flex-col items-center p-4">
      <div className="w-20 h-20 bg-zinc-800 rounded-3xl flex items-center justify-center mb-2 mt-3">
        <img src={logoUrl} alt="Logo" className="w-20 h-20 rounded-3xl" /> {/* Логотип */}
      </div>
      <h3 className="text-white text-lg">{title}</h3> {/* Подпись */}
      <h4 className="text-gray-400 text-sm">{subtitle}</h4> {/* Подзаголовок */}
      <button 
        className="mt-6 bg-zinc-800 bg-opacity-50 text-white rounded-xl w-32 px-4 py-1.5" 
        onClick={() => window.open(link, '_blank')} // Открытие ссылки в новой вкладке
      >
        Кнопка
      </button> {/* Кнопка */}
    </div>
    );

export default CasinoCard;