import React, { useEffect, useState } from 'react';
import { Globe, X, Star, Gift } from 'lucide-react';
import { coinFlipApi } from '../api/coinFlipApi';
import { getUserId, getUserData } from '../config/appConfig';
import { RankDisplay } from './RankDisplay';
import { RankBadge } from './RankBadge';


interface UserProfile {
  firstName: string;
  username: string;
  rank: number;
  progress: number;
  nextLevelPoints: number;
  items: Array<{
    id: string;
    name: string;
    icon: string;
  }>;
  avatar?: string;
  balance?: number;
}

const Navigation: React.FC = () => {
  const [userInitial, setUserInitial] = useState<string>('');
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<string>('russia');
  const [userProfile, setUserProfile] = useState<UserProfile>({
    firstName: '',
    username: '',
    rank: 1,
    progress: 100,
    nextLevelPoints: 399,
    items: [
      { id: '1', name: 'Bonus 1', icon: '🎁' },
      { id: '2', name: 'Bonus 2', icon: '💎' },
      { id: '3', name: 'Bonus 3', icon: '🎯' },
      { id: '4', name: 'Bonus 4', icon: '🎲' },
      { id: '5', name: 'Bonus 5', icon: '🎮' },
    ]
  });

  const regions = [
    { id: 'russia', name: 'Россия' },
    { id: 'kazakhstan', name: 'Казахстан' },
    { id: 'uzbekistan', name: 'Узбекистан' },
  ];

  const fetchBalance = async () => {
    try {
      const balanceData = await coinFlipApi.getUserBalance(getUserId());
      setUserProfile(prev => ({
        ...prev,
        balance: balanceData.balance || 0,
        score: 350 // Здесь должно быть реальное значение из API
      }));
    } catch (error) {
      console.error('Ошибка при получении баланса:', error);
    }
  };

  useEffect(() => {
    const initUser = async () => {
      try {
        // Получаем данные пользователя
        const userData = getUserData();
        if (userData) {
          setUserInitial(userData.first_name.charAt(0).toUpperCase());
          
          setUserProfile(prev => ({
            ...prev,
            firstName: userData.first_name || '',
            username: userData.username || '@username',
            avatar: userData.photo_url || ''
          }));

          // Получаем начальный баланс
          await fetchBalance();
        }
      } catch (error) {
        console.error('Ошибка при инициализации пользователя:', error);
        setUserInitial('T');
      }
    };

    initUser();

    // Периодическое обновление баланса
    const balanceInterval = setInterval(fetchBalance, 5000);

    return () => clearInterval(balanceInterval);
  }, []);

  useEffect(() => {
    if (isRegionModalOpen || isProfileModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isRegionModalOpen, isProfileModalOpen]);

  return (
    <>
      <div className="sticky top-0 bg-black bg-opacity-30 backdrop-blur z-40">
        <div className="max-w-md mx-auto p-4">
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <div className="inline-block bg-zinc-900 rounded-3xl px-6 py-2">
                <span className="text-gray-400">
                  {userProfile.balance?.toLocaleString() || '0'} $
                </span>
              </div>
            </div>
            <div className="flex items-center ml-4 space-x-3">
              <RankBadge 
                avatarUrl={userProfile.avatar}
                userInitial={userInitial}
                onProfileClick={() => setIsProfileModalOpen(true)}
              />
              <div 
                className="w-8 h-8 bg-zinc-900 rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => setIsRegionModalOpen(true)}
              >
                <Globe className="text-gray-400 w-7 h-7" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Профиль модальное окно */}
      {isProfileModalOpen && (
        <div 
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div 
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => setIsProfileModalOpen(false)}
          />
          
          <div className="relative bg-zinc-900 rounded-lg p-6 max-w-sm w-full mx-4 transform translate-y-0">
            <div className="flex justify-end mb-2">
              <button 
                onClick={() => setIsProfileModalOpen(false)}
                className="text-gray-400 hover:text-white"
              >
                <X className="w-4 h-4" />
              </button>
            </div>

            {/* Профиль хедер */}
            <div className="flex items-center space-x-4 mb-6">
              <div className="w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center">
                {userProfile.avatar ? (
                  <img 
                    src={userProfile.avatar} 
                    alt="Avatar" 
                    className="w-full h-full rounded-full object-cover" 
                  />
                ) : (
                  <span className="text-white text-2xl">{userInitial}</span>
                )}
              </div>
              <div>
                <h2 className="text-xl font-bold text-white">{userProfile.firstName}</h2>
                <p className="text-gray-400 text-sm">{userProfile.username}</p>
              </div>
            </div>

            {/* Ранг и прогресс */}

            <div className="mb-6">
            
            <RankDisplay score={userProfile.score} />
              {/* <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                
                  <Star className="w-5 h-5 text-yellow-500 mr-2" />
                  <span className="text-white">Rank: #{userProfile.rank}</span>
                </div>
                <span className="text-gray-400 text-sm">
                  {userProfile.progress}/{userProfile.nextLevelPoints}
                </span>
              </div>
              <div className="w-full h-2 bg-gray-700 rounded-full">
                <div 
                  className="h-full bg-blue-600 rounded-full"
                  style={{ width: `${(userProfile.progress / userProfile.nextLevelPoints) * 100}%` }}
                />
              </div> */}
            </div>

            {/* Бонусы */}
            <div>
              <div className="flex items-center mb-4">
                <Gift className="w-5 h-5 text-blue-500 mr-2" />
                <h3 className="text-white font-semibold">Items</h3>
              </div>
              <div className="grid grid-cols-4 gap-3">
                {userProfile.items.map(item => (
                  <div 
                    key={item.id}
                    className="aspect-square bg-zinc-800 rounded-lg flex items-center justify-center text-2xl"
                    title={item.name}
                  >
                    {item.icon}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Регион модальное окно (оставляем как есть) */}
      {isRegionModalOpen && (
        <div 
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div 
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => setIsRegionModalOpen(false)}
          />
          
          <div className="relative bg-zinc-900 rounded-lg p-6 max-w-sm w-full mx-4 transform translate-y-0">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-white">Регион</h2>
              <button 
                onClick={() => setIsRegionModalOpen(false)}
                className="text-gray-400 hover:text-white"
              >
                <X className="w-4 h-4" />
              </button>
            </div>

            <div className="space-y-3">
              {regions.map((region) => (
                <label 
                  key={region.id} 
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <div className="relative flex items-center">
                    <input
                      type="radio"
                      name="region"
                      value={region.id}
                      checked={selectedRegion === region.id}
                      onChange={(e) => setSelectedRegion(e.target.value)}
                      className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500 hidden"
                    />
                    <div className={`w-4 h-4 rounded-full border ${
                      selectedRegion === region.id 
                        ? 'border-blue-500 bg-blue-500' 
                        : 'border-gray-400'
                    }`}>
                      {selectedRegion === region.id && (
                        <div className="w-2 h-2 bg-white rounded-full absolute top-1 left-1" />
                      )}
                    </div>
                  </div>
                  <span className="text-gray-200">{region.name}</span>
                </label>
              ))}
            </div>

            <button
              onClick={() => setIsRegionModalOpen(false)}
              className="w-full bg-blue-600 text-white rounded-lg py-2 mt-6 hover:bg-blue-700 transition-colors"
            >
              Сохранить и закрыть
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;