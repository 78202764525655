// src/api/rankApi.ts

import axios from 'axios';

interface RankInfo {
  currentRank: {
    id: number;
    title: string;
    color: string;
    icon: string;
    min_score: number;
  };
  nextRank: {
    id: number;
    title: string;
    min_score: number;
  } | null;
  score: number;
  progress: number;
  pointsToNextRank: number | null;
}

interface ScoreHistoryItem {
  id: number;
  score_change: number;
  reason: string;
  created_at: string;
}

const API_BASE_URL = '/api';

export const rankApi = {
  // Получить информацию о ранге пользователя
  getUserRankInfo: async (userId: number): Promise<RankInfo> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userId}/rank`);
      return response.data;
    } catch (error) {
      console.error('Error fetching rank info:', error);
      throw error;
    }
  },

  // Получить историю изменений очков
  getScoreHistory: async (userId: number, limit: number = 10): Promise<ScoreHistoryItem[]> => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userId}/score-history`, {
        params: { limit }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching score history:', error);
      throw error;
    }
  }
};