import React from 'react';

interface BonusCardProps {
    imgUrl: string; // URL изображения
    logoUrl: string; // URL логотипа
    link: string;    // Ссылка для кнопки
  }

  const BonusCard: React.FC<BonusCardProps> = ({ logoUrl, link, imgUrl }) => (
    <div className="bg-zinc-900 rounded-3xl w-80 h-52 flex-shrink-0 flex flex-col items-center p-0 relative overflow-hidden">
      <img src={imgUrl} alt="Bonus" className="w-full h-full object-cover" /> {/* Изображение во всю высоту и ширину */}
      
      <button 
        className="absolute bottom-4 left-4 bg-zinc-800 bg-opacity-70 backdrop-blur text-white rounded-xl w-32 px-4 py-1.5 flex items-center justify-between" 
        onClick={() => window.open(link, '_blank')} // Открытие ссылки в новой вкладке
      >
        <img src={logoUrl} alt="Logo" className="w-8 h-8 rounded-full" /> {/* Логотип внутри кнопки */}
        <span>Кнопка</span> {/* Текст кнопки */}
      </button> {/* Кнопка */}
    </div>
  );

export default BonusCard;