// src/components/RankBadge.tsx

import React from 'react';
import { useRankInfo } from '../hooks/useRankInfo';
import { getUserId } from '../config/appConfig';

interface RankBadgeProps {
  avatarUrl?: string;
  userInitial: string;
  onProfileClick: () => void;
}

export const RankBadge: React.FC<RankBadgeProps> = ({ 
  avatarUrl, 
  userInitial,
  onProfileClick 
}) => {
  const userId = getUserId();
  const { rankInfo, loading } = useRankInfo(userId);

  return (
    <button 
      onClick={onProfileClick}
      className="relative w-8 h-8"
    >
      {/* Прогресс-кольцо */}
      <svg 
        className="absolute inset-0 w-full h-full -rotate-90"
        viewBox="0 0 32 32"
      >
        <circle
          cx="16"
          cy="16"
          r="15"
          className="fill-none stroke-zinc-800"
          strokeWidth="2"
        />
        
        {!loading && rankInfo && (
          <circle
            cx="16"
            cy="16"
            r="15"
            className="fill-none stroke-blue-500"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray={`${rankInfo.progress * 0.94} 100`}
          />
        )}
      </svg>

      {/* Аватар/Инициал */}
      <div className="absolute inset-0.5 rounded-full bg-zinc-900 flex items-center justify-center overflow-hidden">
        {avatarUrl ? (
          <img 
            src={avatarUrl} 
            alt="User avatar" 
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-white text-sm">{userInitial}</span>
        )}
      </div>

      {/* Номер ранга */}
      {!loading && rankInfo && (
        <div className="absolute -bottom-1.5 -right-1.5 w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center border-2 border-black">
          <span className="text-white text-xs font-medium">
            {rankInfo.currentRank.id}
          </span>
        </div>
      )}
    </button>
  );
};