import React from 'react';
// import Navigation from '../components/Navigation';
import FeatureCard from '../components/FeatureCard';
// import CasinoCard from '../components/CasinoCard';
import BonusCard from '../components/BonusCard';
import GameCard from '../components/GameCard';
import TableCard from '../components/TableCard';
// import BottomNav from '../components/BottomNav';

const freespinsOffers = [
  { 
    logoUrl: "/images/1win.jpg", 
    title: "Play Fortune", 
    bonusTypes: ["100 FS", "500$"], 
    link: "https://1win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "2Win", 
    bonusTypes: ["50 FS", "100 %"], 
    link: "https://2win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "1Win", 
    bonusTypes: ["100 FS", "5000 ₽"], 
    link: "https://1win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "2Win", 
    bonusTypes: ["50 FS", "10000 ₽"], 
    link: "https://2win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "1Win", 
    bonusTypes: ["100 FS", "5000 ₽"], 
    link: "https://1win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "2Win", 
    bonusTypes: ["50 FS", "10000 ₽"], 
    link: "https://2win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "1Win", 
    bonusTypes: ["100 FS", "5000 ₽"], 
    link: "https://1win.com" 
  },
  { 
    logoUrl: "/images/1win.jpg", 
    title: "2Win", 
    bonusTypes: ["50 FS", "10000 ₽"], 
    link: "https://2win.com" 
  },
  // ... остальные офферы
];


const FavoritesPage: React.FC = () => {
  React.useEffect(() => {
    const initTelegram = () => {
      try {
        const webApp = window.Telegram?.WebApp;
        if (webApp) {
          // Сначала вызываем ready()
          webApp.ready();
          // Затем уже можно вызывать другие методы
          if (typeof webApp.disableSwipeToDismiss === 'function') {
            webApp.disableSwipeToDismiss();
          }
        }
      } catch (error) {
        console.error('Ошибка при инициализации Telegram WebApp:', error);
      }
    };

    // Добавим небольшую задержку для уверенности, что WebApp загрузился
    setTimeout(initTelegram, 100);

    return () => {
      // Cleanup если необходим
    };
  }, []);


  return (
    <div className="min-h-screen pb-16">
      {/* <Navigation /> */}
      
      <div className="max-w-md mx-auto p-4 text-white">
        <FeatureCard 
          title="Wheel of Fortune"
          imageUrl="/images/image1.jpg"  // Обновлённый путь
          className="h-48 mb-4"
        />
        
        <div className="flex gap-4 mb-4">
          <FeatureCard
            title="Gift box"
            imageUrl="/images/image1.jpg"  // Обновлённый путь
            className="h-40 flex-[1]"
          />
          <FeatureCard
            title="Bonus"
            imageUrl="/images/image1.jpg"  // Обновлённый путь
            className="h-40 flex-[2]"
          />
        </div>
        {/* <div>
          <h2 className="text-xl mb-4">Top 10 online casino</h2>
          <div className="carousel-container">
            <div className="flex space-x-4 pb-4">
              
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 1" subtitle="Лучшие игры" link="https://casino1.com" />
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 2" subtitle="Лучшие игры" link="https://casino2.com" />  
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 1" subtitle="Лучшие игры" link="https://casino1.com" />
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 2" subtitle="Лучшие игры" link="https://casino2.com" /> 
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 1" subtitle="Лучшие игры" link="https://casino1.com" />
                <CasinoCard logoUrl="/images/1win.jpg" title="Казино 2" subtitle="Лучшие игры" link="https://casino2.com" /> 
              
            </div>
          </div>
        </div> */}
        <div>
          <h2 className="text-xl mb-4">Top Bonuses</h2>
          <div className="carousel-container">
            <div className="flex space-x-4 pb-4">
              
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image1.jpg" link="https://casino1.com" />
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image2.jpg" link="https://casino2.com" />
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image3.jpg" link="https://casino1.com" />
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image1.jpg" link="https://casino2.com" /> 
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image1.jpg" link="https://casino1.com" />
                <BonusCard logoUrl="/images/1win.jpg" imgUrl="/images/image1.jpg" link="https://casino2.com" /> 
              
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-xl mb-4">Top Games</h2>
          <div className="carousel-container">
            <div className="flex space-x-4 pb-4">
              
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game1.png" link="https://casino1.com" />
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game2.png" link="https://casino2.com" />
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game3.png" link="https://casino1.com" />
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game4.png" link="https://casino2.com" /> 
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game1.png" link="https://casino1.com" />
                <GameCard logoUrl="/images/1win.jpg" imgUrl="/images/game1.png" link="https://casino2.com" /> 
              
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-xl mb-4">Top Freespins</h2>
          <TableCard offers={freespinsOffers} />
        </div>
      </div>


      {/* <BottomNav /> */}
    </div>
  );
};
export default FavoritesPage;